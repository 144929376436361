<template>
  <div class="phone-group">
    <!--<label class="form-control-label"> {{ label }} </label>-->
    <div
      v-for="(line, key) in phoneNumbersModel"
      :key="key"
      class="phone-group-wrapper"
    >
      <div class="phone-group-wrapper-item">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="line.type"
            @change="
              (type) => {
                line.type = type;
                phonesChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="phone-group-wrapper-item">
        <base-input :label="`${$t('COMMON.PHONE')}`">
          <phone-number-input
            :phoneNumber="line.phoneNumber"
            @phoneNumberChanged="
              (phone) => {
                line.phoneNumber = phone;
                phonesChanged();
              }
            "
          />
        </base-input>
      </div>
      <div class="phone-group-wrapper-item">
        <base-input
          :label="$t('COMMON.EXTENSION')"
          :placeholder="$t('COMMON.EXTENSION')"
          v-model="line.extension"
          @change="phonesChanged"
          :inputClasses="'extension-input'"
        />
      </div>
      <div class="phone-group-wrapper-item remove">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn"
          @click.prevent="() => removePhone(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button type="button" class="btn add" @click.prevent="addPhone">
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import { phoneTypesOptions, PHONE_TYPE_MOBILE } from "@/constants/common";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import PhoneNumberInput from "./PhoneNumberInput.vue";

export default {
  name: "phone-numbers-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    PhoneNumberInput,
  },

  props: {
    phoneNumbers: {
      type: Array,
      default: null,
      description: "Phones array",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      phoneNumbersModel: this.phoneNumbers,
      phoneTypesOptions,
    };
  },

  setup() {},

  created() {},

  methods: {
    addPhone() {
      this.phoneNumbersModel.push({
        phoneNumber: null,
        extension: null,
        type: PHONE_TYPE_MOBILE,
      });
      this.phonesChanged();
    },
    removePhone(key) {
      this.phoneNumbersModel = this.phoneNumbersModel.filter(
        (_, idx) => idx !== key
      );
      this.phonesChanged();
    },
    phonesChanged() {
      const phonesData = this.phoneNumbersModel.map((item) => {
        return {
          phoneNumber: item.phoneNumber,
          extension: item.extension,
          type: item.type,
        };
      });
      this.$emit("phoneNumbersChanged", phonesData);
    },
  },

  watch: {
    phoneNumbers(phoneNumbers) {
      this.phoneNumbersModel = cloneDeep(phoneNumbers);
    },
  },
};
</script>
